import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import "./bannersection.less";
import { Row, Typography } from "antd";
import { Link } from "react-router-dom";
const { Title, Paragraph, Text } = Typography;

class BannerSection extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        {this.props.loading ? (
          <Row justify="center" align="center">
            <Title style={{ color: "white" }} level={2}>
              LOADING...
            </Title>
          </Row>
        ) : (
          <Typography>
            <Row justify="center" align="center">
              <Title level={2}>Choose a Showroom Template</Title>
            </Row>
            <Row justify="center" align="center">
              <Paragraph>
                See them on your phone via QR code? Select and build a showroom
                with fpo products or upload your own 3D files
              </Paragraph>
            </Row>
            <Row justify="center" align="center">
              <Paragraph>
                Looking for a truly unique flagship space?{" "}
                <Link to="/showrooms/bespoke"> Create a bespoke showroom</Link>
              </Paragraph>
            </Row>
          </Typography>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.showrooms.loading,
  };
};

export default connect(mapStateToProps, {})(withRouter(BannerSection));
