import React from "react";
import { Provider } from "react-redux";
import MainContainer from "./mainContainer";
import Helmet from "react-helmet";
import store from "./redux/index";
import { BrowserRouter } from "react-router-dom";
import favicon from "../asset/favicon.png";

export default function Root(props) {
  return (
    <section>
      <Provider store={store}>
        <BrowserRouter history={history}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Bitreel Showrooms</title>
            <link rel="shortcut icon" id="favicon" href={favicon}></link>
          </Helmet>
          <MainContainer />
        </BrowserRouter>
      </Provider>
    </section>
  );
}
