import React, { Component } from "react";
import { Route, withRouter } from "react-router";
import { connect } from "react-redux";
import { Layout } from "antd";
import { LOGIN_URL, NO_AUTH_URLS } from "../constants";
import { sharedInterface } from "@Bitreel/portal-login";
import SiderMenu from "./sidermenu";
import BespokeHome from "./bespoke/bespokeHome";
const { Content } = Layout;

import "./mainContainer.less";
import TemplateHome from "./templates/templateHome";

class MainContainer extends Component {
  componentDidMount() {
    const { auth } = this.props;
    if (!auth().token && !NO_AUTH_URLS.includes(window.location.pathname)) {
      window.location.href = window.location.origin + LOGIN_URL;
    }
  }

  render() {
    const { auth } = this.props;
    return (
      <>
        <Layout style={{ paddingTop: "60px" }}>
          <SiderMenu auth={auth} />
          <Layout style={{ marginLeft: 200 }}>
            <Content className="showrooms_content">
              <Route exact path="/showrooms">
                <TemplateHome />
              </Route>
              <Route exact path="/showrooms/bespoke">
                <BespokeHome />
              </Route>
            </Content>
          </Layout>
        </Layout>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: sharedInterface,
  };
};

export default connect(mapStateToProps, {})(withRouter(MainContainer));
