import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import "./templateHome.less";
import {
  Button,
  Card,
  Col,
  Divider,
  Image,
  Row,
  Space,
  Typography,
} from "antd";
import BannerSection from "./bannerSection";
import { map } from "lodash";
import { Link } from "react-router-dom";

const { Title, Paragraph, Text } = Typography;

class TemplateHome extends Component {
  constructor(props) {
    super(props);
    this.buildList = this.buildList.bind(this);
  }

  buildList() {
    return map(this.props.templates, (template, index) => {
      return (
        <Col className="showroom_template" key={template.id} span={10}>
          <Image
            className="template_preview_image"
            preview={false}
            width={402}
            height={248}
            src={template.imgSrc}
          />
          <Title level={5}>{template.name}</Title>
          <Paragraph className="template_description">
            {template.description}
          </Paragraph>
          <Space>
            <Button className="showroom_secondary_button">PREVIEW</Button>
            <Button className="showroom_primary_button" type="primary">
              GET STARTED
            </Button>
          </Space>
        </Col>
      );
    });
  }
  render() {
    return (
      <>
        <BannerSection />
        <Row justify="center" className="template_list">
          {this.buildList()}
        </Row>
        <Row justify="center">
          <Card className="bespoke_showroom_bottom_Card">
            <Row justify="center" aligh="center">
              <Title level={5}>Not seeing what you need?</Title>
            </Row>
            <Row justify="center" aligh="center">
              <Link to={"/showrooms/bespoke"}>
                <Button className="showroom_secondary_button">
                  BUILD A BESPOKE SHOWROOM
                </Button>
              </Link>
            </Row>
          </Card>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    templates: state.showrooms.templates,
  };
};

export default connect(mapStateToProps, {})(withRouter(TemplateHome));
