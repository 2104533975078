import ReduxThunk from "redux-thunk";
import { createStore, compose, applyMiddleware } from "redux";
import reducers from "./reducers/combinedReducers";

export default createStore(
  reducers,
  undefined,
  compose(
    applyMiddleware(ReduxThunk),
    window.devToolsExtension ? window.devToolsExtension() : (f) => f
  )
);
