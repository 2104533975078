import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import "./bespokeHome.less";
import { Row, Typography } from "antd";
import { Link } from "react-router-dom";
const { Title, Paragraph, Text } = Typography;

class BespokeHome extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <div>bespoke</div>;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(mapStateToProps, {})(withRouter(BespokeHome));
