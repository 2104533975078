import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { Button, Card, Divider, Layout, Menu, Typography } from "antd";
const { Paragraph, Text } = Typography;
const { Sider } = Layout;
import "./sidermenu.less";
import { Link } from "react-router-dom";

class SiderMenu extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Sider className="home_side_nav">
        <div className="logo" />
        <Card bordered={false}>
          <h2>Showrooms</h2>
          <Paragraph>You currently have no showrooms created</Paragraph>
        </Card>
        <Divider />

        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={[this.props.location.pathname]}
          selectedKeys={[this.props.location.pathname]}
        >
          <Menu.Item key="/showrooms">
            <Link to={"/showrooms"}>Showroom Templates</Link>
          </Menu.Item>
          <Menu.Item key="/showrooms/bespoke">
            <Link to={"/showrooms/bespoke"}>Bespoke Showrooms</Link>
          </Menu.Item>
        </Menu>
      </Sider>
    );
  }
}

const mapStateToProps = ({}) => {
  return {};
};

export default connect(mapStateToProps, {})(withRouter(SiderMenu));
