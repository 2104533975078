const defaultState = {
  loading: false,
  templates: [
    {
      id: "helmanchang",
      name: "Hellman Chang",
      description:
        "Discover a new way to browse our collection and preview pieces in your own space. Walk around or move your thumb on the screen to navigate the virtual space… and turn up the volume on your device for the full experience.",
      imgSrc:
        "https://bitreel-arshowrooms.s3.amazonaws.com/hellmanchang/preview.jpg",
    },
    {
      id: "helmanchang1",
      name: "Hellman Chang",
      description:
        "Discover a new way to browse our collection and preview pieces in your own space. Walk around or move your thumb on the screen to navigate the virtual space… and turn up the volume on your device for the full experience.",
      imgSrc:
        "https://bitreel-arshowrooms.s3.amazonaws.com/hellmanchang/preview.jpg",
    },
    {
      id: "helmanchang2",
      name: "Hellman Chang",
      description:
        "Discover a new way to browse our collection and preview pieces in your own space. Walk around or move your thumb on the screen to navigate the virtual space… and turn up the volume on your device for the full experience.",
      imgSrc:
        "https://bitreel-arshowrooms.s3.amazonaws.com/hellmanchang/preview.jpg",
    },
    {
      id: "helmanchang3",
      name: "Hellman Chang",
      description:
        "Discover a new way to browse our collection and preview pieces in your own space. Walk around or move your thumb on the screen to navigate the virtual space… and turn up the volume on your device for the full experience.",
      imgSrc:
        "https://bitreel-arshowrooms.s3.amazonaws.com/hellmanchang/preview.jpg",
    },
  ],
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case "UPDATE_LOADING": {
      return {
        ...state,
        loading: action.loading,
      };
    }
    default:
      return state;
  }
}
